import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { List, ListItem, ListItemText } from '@material-ui/core';
export const MenuInstagram = () => {
    const instagramId = "CDuG3xIjFQy";
    const data = useStaticQuery(graphql`
    {
      allInstaNode(filter: {id: {eq: "CDuG3xIjFQy"}}) {
        edges {
          node {
            localFile {
              childImageSharp{
                  fluid(maxWidth:600){
                      ...GatsbyImageSharpFluid
                  }
              }
            }
          }
        }
      }
    }
    `);
    // console.log(data);
    return <Img fluid={data.allInstaNode.edges[0].node.localFile.childImageSharp.fluid} />;
}

export const Menu = () => {
    let empanadas : {name : string, description : string, price : number}[]= [
        {name:`Vegan Beef and Potato`,description:"Delicious vegan beef and potato filling.", price:4},
        {name:`Creamy Spinach and Ricotta`,description:"Creamy spinach and cashew mozzarella empanada.", price:4},
        {name:`Vegan Ham and Cheese`,description:"Delicious vegan ham and cheese filling.", price:4},
        // {name:`Savory Mushroom and Onion`, description:"Savory and delicious mushroom and onion empanada", price:4},
        // {name:`Curry Chick'n`,description:"Vegan curry seasoned chicken empanada", price:4},
        // {name:`Cinnamon Apple Pie`,description:"Delicious dessert cinnamon apple pie empanada", price:4},
        // {name:`Breakfast Empanada`,description:"Start your day off right with a vegan breakfast empanada!", price:4},
    ];

    // let sauces : {name : string, price : number, description? : string}[]= [
    //     {name:`Garlic Cilantro Mayo`, price : 1},
    //     {name:"Nacho Cheese Sauce", price : 1, description:"Nut free"}
    // ]
    return (
        <List>
            <div style={{margin:'auto', textAlign:'center', padding:'20px'}}>
              <h1>Menu</h1>
              <h2>Empanadas $4</h2>
                {empanadas.map((empanada) => {
                  return(
                  <ListItem>
                    <ListItemText primary={empanada.name} secondary={empanada.description} style={{textAlign:'center'}}/> 
                  </ListItem>)})}
              {/* <h2>Sauces $1</h2>
                {sauces.map((sauce) => 
                <ListItem>
                  <ListItemText 
                  primary={sauce.name} 
                  secondary={sauce.description ? sauce.description : undefined}
                  style={{textAlign:'center'}}/>
                  </ListItem>)} */}
          </div>
        </List>
        
    );
}

