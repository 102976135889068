import React from 'react';
import {Logo} from '../image';
import InstagramIcon from '@material-ui/icons/Instagram';
import {Menu} from './Menu';
import { GridList, GridListTile, Button} from '@material-ui/core';
import { useStaticQuery, graphql} from 'gatsby';
const Home = () => {
    
    
    return (
        <div>
            <div style={{margin:'auto', textAlign:'center', padding:'20px', maxWidth:'800px'}}>
                <h1>The Vegan Dive</h1>
                <h3>Fresh, delicious, homemade vegan food!</h3>
                <Button 
                    href="mailto:Thevegandivellc@gmail.com" 
                    style={{backgroundColor:'#3190B7', color:'white', fontWeight:'bolder'}}
                    variant="contained">
                ORDER NOW!
                </Button>
                <div style={{paddingTop:'25px'}}>
                    <a href="https://www.instagram.com/thevegandive/">
                        <InstagramIcon style={{fontSize:'64px'}} htmlColor={"#3190B7"}/>
                    </a>
                </div>
                
                <Menu/>
            </div>
        </div>
        );
}

const InstagramGridList = () => {
    const data = useStaticQuery(graphql`
    {
    allInstaNode {
        edges {
            node {
            caption
            likes
            timestamp
            localFile {
                publicURL
              }
            }
        }
    }
    }
    `);
    let tileData :{img:any, title:string,author:string,cols:number}[]= [];
    

    // sort the data from most recent -> oldest
    data.allInstaNode.edges.sort((a : any, b : any) => a.node.timestamp - b.node.timestamp);

    // get all picture data and add to feed prop
    data.allInstaNode.edges.forEach((e : any, i : number) => {
        let cols = 1;

        /** when i === 
         * 0
         * 6
         * 10
         * 16 etc.
         * 
        */
        if (i % 10 === 0 || i % 10 === 6) {
            cols = 2;
        }

        tileData.push({
            img: e.node.localFile.publicURL,
            title: `img${i}`,
            author: `@thevegandive`,
            cols: cols
        });
    });

    return(<>
        <GridList cellHeight={160} style={{margin: 'auto', width:800, height:1000}} cols={3}>
            {tileData.map((tile) => (
                <GridListTile key={tile.img} cols={tile.cols || 1}>
                    <img src={tile.img} alt={tile.title} />
                </GridListTile>
            ))}
        </GridList> 
    </>)
}

export default Home;